//----------------------------------------------------
// Colours
//----------------------------------------------------

$dark-blue:  #005283;
$light-blue: #009cdf;
$grey: #eee;
$black: #222;
$white: #fff;

$red: #f00;

$link-color: $dark-blue;
$link-color-hover: $light-blue;

$accessibility: $red;
$accessibility-background: rgba(0, 156, 223, .3);

$highlight: rgba(0,156,223,.3);

//----------------------------------------------------
//  specific colours
//----------------------------------------------------
$page-header-background: rgba(0, 156, 223, .8);
$feature-gradient-top: rgba(0, 0, 0, 0);
$feature-gradient-bottom: rgba(0, 0, 0, 1);
$icon-border-color: $grey;

$table-head-border-color: rgba(0, 156, 223, .2);
$translucent-border: rgba(0, 0, 0, .1);


//----------------------------------------------------
//  fonts
//----------------------------------------------------

$font-sans: "proxima-nova-soft", "Proxima Nova Soft", 'Helvetica Neue', helvetica, arial, verdana, sans-serif !default;
$font-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-mono: Monaco, Menlo, Consolas, 'Courier New', monospace !default;
$font-heading: "proxima-nova-soft", "Proxima Nova Soft", 'Helvetica Neue', helvetica, arial, verdana, sans-serif !default;

//----------------------------------------------------
//  base typography
//----------------------------------------------------

$font-size: 15px;

$font-size-h1: 40px;
$font-size-h2: 22px;
$font-size-h3: 25px;
$font-size-h4: 19px;
$font-size-h5: 19px;
$font-size-h6: 14px;

$line-height-h1: 30px;
$line-height-h2: 26px;
$line-height-h3: 21px;
$line-height-h4: 18px;
$line-height-h5: 17px;
$line-height-h6: 14px;

$line-height-base: 1.4;

$font-weight-light: lighter;
$font-weight-regular: normal;
$font-weight-bold: bold;


$grid-row-height: 256px;


$wrapper-width-desktop: 1024px;
$wrapper-width-mobile: 700px;
