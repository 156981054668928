//----------------------------------------------------
//      footer
//----------------------------------------------------
footer {
  height: 116px;
}

.logos {
  @include cf();
  display: inline-block;
  float: left;
  height: 100%;

  a {
    display: inline-block;
    height: 100%;
  }

  img {
    height: 100%;
    padding: 33px 37px;
  }

}

.meta {
  display: inline-block;
  float: right;
  height: 100%;
  padding: 33px 42px 40px;

  ul {
    clear: right;
    display: block;
    float: right;
    margin-bottom: 14px;
    text-align: center;
  }

  li {
    display: inline;
    padding-left: 9px;

    + li::before {
      color: $table-head-border-color;
      content: '\2022';
      padding-right: 9px;
    }

    &:first-child {
      padding-left: 0;
    }
  }
}


@media screen and (max-width:$wrapper-width-mobile - 1) {
  footer {
    height: 181px;
  }

  .logos {
    display: block;
    float: none;
    height: 91px;
    position: relative;
    width: 100%;

    a {
      margin: 0 50%;
      position: absolute;
      transform: translate(-50%, 0);
    }

    img {
      padding: 20px 0;
      position: relative;
    }
  }

  .meta {
    float: none;
    height: 90px;
    padding: 0;
    width: 100%;

    ul {
      float: none;
      height: 44px;
      margin-bottom: 0;
      padding: 14px 22px 16px;
    }

    .links {
      background-color: $white;
    }

    .copyright li {

      + li::before {
        content: '';
      }

      &:first-child {
        float: left;
      }

      &:last-child {
        float: right;
      }
    }
  }
}
