//----------------------------------------------------
//      webfont declarations
//----------------------------------------------------
nav,
.nav-mobile,
footer {
  color: $dark-blue;
  font-size: 15px;
  line-height: 15px;
}

.grid-info h3 {
  font-size: 19px;
  line-height: 19px;
}

.copyright {
  color: $black;
}

@media screen and (min-width: 1400px) {
  .grid-info {
    h3 {
      font-size: 25px;
      line-height: 27px;
    }

    p {
      font-size: 20px;
      line-height: 22px;
    }
  }
}

@media screen and (min-width:$wrapper-width-mobile) and (max-width:$wrapper-width-desktop - 1) {
  .grid-info {
    h3 {
      font-size: 15px;
      line-height: 17px;
    }

    p {
      font-size: 13px;
      line-height: 14px;
    }
}

}

@media screen and (max-width:$wrapper-width-mobile - 1) {

  footer {
    font-size: 14px;
  }

  h4 {
    font-size: 15px;
  }

  p {
    font-size: 15px;
    line-height: 20px;
  }

  .title-wrapper {
    h1 {
      font-size: 26px;
      line-height: 28px;
    }

    h2 {
      font-size: 16px;
    }
  }

  .grid-info {
    h3 {
      font-size: 15px;
      line-height: 17px;
    }

    p {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .copyright {
    font-size: 13px;
  }

}
