body {
    background-color: $grey;
    color: $black;
    font-family: $font-sans;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $dark-blue;
    font-family: $font-heading;
}

@each $header, $size, $line-height in (h1, $font-size-h1, $line-height-h1),
(h2, $font-size-h2, $line-height-h2),
(h3, $font-size-h3, $line-height-h3),
(h4, $font-size-h4, $line-height-h4),
(h5, $font-size-h5, $line-height-h5),
(h6, $font-size-h6, $line-height-h6) {
    #{$header} {
        display: inline-block;
        font-size: $size;
        line-height: $line-height;
    }
}

h1 {
    background-color: $dark-blue;
    color: $white;
    padding: 15px 17px 11px;
}

h2 {
    background-color: $page-header-background;
    color: $white;
    padding: 16px 20px 20px;
}

// Borrowing the .visuallyhidden class from helpers
.hidden-desktop {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

//----------------------------------------------------
//  other base styles
//----------------------------------------------------

// main {
//     position: relative;
// }

a {
    color: $link-color;
    text-decoration: none;

    &:hover {
        color: $light-blue;
        text-decoration: underline;
        text-decoration-color: $light-blue;
    }
}

ul {
    list-style: none;
}

header,
footer,
article,
main,
.content-wrapper {
    margin: 0 auto;
    width: 100%;
}

main,
article,
.content-wrapper {
    background-color: $white;
}

.videoWrapper {
    position: relative;
    padding-top: 56.25%; /* 16:9 */
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

//----------------------------------------------------
//      grid stylings
//----------------------------------------------------
.row {
    position: relative;
    width: 100%;
    @include cf;

    &.row-double-height [class*='col-'] {
        padding-top: 50%;
    }

    &.alt {
        position: relative;

        .col-2:first-child {
            position: absolute;
            right: 0;
        }
    }

    &.alt:first-child {
        float: right;
    }

    p {
        color: $black;
    }

    a:hover {
        .grid-info:not(.feature-text) h3 {
            color: $link-color-hover;
        }

        .icon-chevron {
            left: 36px;
        }
    }
}

[class*='col-'] {
    float: left;
    height: 0;
    padding-top: 25%;
    position: relative;
    width: 25%;
}

.col-2 {
    width: 50%;
}

.col-3 {
    width: 75%;
}

.col-4 {
    width: 100%;
}

.grid-img {
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.feature {
    background-color: $dark-blue;
    height: 100%;
    position: absolute;
    width: 100%;

    &.feature-top {
        background-image: url('../img/money_matters_large.png');
        top: 0;

        h2 {
            background-color: transparent;
            font-size: 1.5vw;
            line-height: 1.08;
            padding: 70.93% 13.281% 0;
            text-align: center;
        }

    }

    &.feature-bottom {
        bottom: 0;
        top: auto;
    }

    a {
        display: block;
        height: 100%;
    }
}

.grid-info {
    background-color: $white;
    padding: 15px;
    z-index: 1;

    > div {
        position: relative;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
    }

    h3,
    p {
        width: 100%;
    }

    p {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 8px;
        padding-top: 8px;
    }

    a {
        display: inline-block;
    }

    &.feature-text {
        background: linear-gradient(to bottom, $feature-gradient-top, $feature-gradient-bottom);
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 52%;
        justify-content: flex-end;
        position: absolute;
        text-align: center;
        width: 100%;

        h3 {
            color: $white;
        }

        p {
            color: $white;
            margin: 0 auto 18px;
            max-width: 400px;
            position: relative;
        }

        .info-icon {
            top: 0;
        }
    }

    &.arrow-left::after,
    &.arrow-right::after,
    &.arrow-up::after {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }

    &.arrow-left::after {
        left: -10px;
        @include arrow($width: 10px, $height: 20px, $color: $white, $direction: left);
    }

    &.arrow-right::after {
        right: -10px;
        @include arrow($width: 10px, $height: 20px, $color: $white, $direction: right);
    }

    &.arrow-up::after {
        left: 50%;
        top: -10px;
        transform: translate(-50%, 0);
        @include arrow($width: 20px, $height: 10px, $color: $dark-blue, $direction: up);
    }
}

.rectangle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.half-height {
        height: 50%;
    }

    &:last-child {
        bottom: 0;
        top: auto;
    }

    > a {
        display: block;
        height: 100%;

        > div {
            height: 100%;
            position: absolute;
            width: 50%;
        }

        .quarter-width {
            width: 25%;
        }

        .three-quarter-width {
            width: 75%;
        }
    }

    .grid-img,
    &.alt .grid-info {
        left: 0;
    }

    .grid-info,
    &.alt .grid-img {
        right: 0;
    }

    &.alt .grid-img {
        left: auto;
    }

    &.alt .grid-info {
        right: auto;
    }
}

.info-icon {
    background-color: transparent;
    border: 2px solid $icon-border-color;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    margin: 15px auto 0;
    overflow: hidden;
    position: relative;
    transition: all .5s linear;
    width: 24px;

    i {
        color: $light-blue;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        transition: all .25s linear;

        &.icon-chevron {
          font-size: 11px;
          left: 56%;
          transition: left 750ms;
        }

        &.icon-chevron::after {
            content: '\f054';
            left: -24px;
            position: absolute;
        }
    }

    .info-box & {
        border-color: $white;
    }

    &:active,
    &.is-active {
        background-color: $light-blue;

        i {
          color: $white;
      }
    }
}

//----------------------------------------------------
//      Single page styles
//----------------------------------------------------
.page-header {
  background-position: center;
  background-size: cover;
  height: 338px;
  position: relative;
}

.title-wrapper {
  bottom: 0;
  left: 50%;
  padding-left: 42px;
  position: absolute;
  transform: translate(-50%, 0);
  width: 1024px;

  h1,
  h2 {
    clear: left;
    float: left;
    line-height: 1;
    padding: 20px;
    position: relative;
}

h1 {
    max-width: 80%;
}

h2 {
    line-height: 1.1;
    max-width: 90%;
}
}

.content-wrapper {
  background-color: $white;
  max-width: 1024px;
  padding: 44px 42px 44px 62px;
  width: 100%;
  @include cf();
}

article {
  float: left;
  margin-top: 40px;
  padding-right: 48px;
  width: 66%;

  h3 {
    padding-bottom: 18px;
}

iframe {
    width: 100% !important;
}

img {
    margin: 1em 0;
    max-width: 100%;
}

&:first-of-type {
    margin-top: 0;
}

> div {
    width: 100%;

    table:first-child {
      margin-top: 0;
  }

  > p:last-child,
  table:last-child {
      margin-bottom: 0;
  }
}

p {
    margin-bottom: 1em;

    a {
      text-decoration: underline;

      &:hover {
        color: $light-blue;
        text-decoration-color: $light-blue;
    }
}
}

> p:last-child {
    margin-bottom: 0;
}

ul {
    list-style: none;

    + p {
      margin-top: 2em;
  }

  li:last-child {
      margin-bottom: 0;
  }

  ul {
      margin: 20px 0;

      li::before {
        background-color: transparent;
        border-radius: 0;
        color: $light-blue;
        content: '\f054';
        font-family: 'FontAwesome';
        font-size: 10px;
        top: 3px;
    }
}
}

li {
    margin-bottom: .5em;
    padding-left: 20px;
    position: relative;

    &::before {
      background-color: $dark-blue;
      border-radius: 50%;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      top: 6px;
      width: 8px;
  }
}

}

.expandable-section {

    h4 {
        border-top: 1px solid $grey;
        cursor: pointer;
        line-height: 24px;
        padding: 15px 0 15px 42px;
        position: relative;
        width: 100%;

        &::before {
            background-color: transparent;
            border: 1px solid $icon-border-color;
            border-radius: 50%;
            content: '+';
            display: block;
            height: 24px;
            left: 0;
            line-height: 23px;
            margin-top: -12px;
            overflow: hidden;
            position: absolute;
            text-align: center;
            top: 50%;
            transform-origin: 50% 50%;
            transition: all .25s linear;
            width: 24px;
        }
    }

    button:hover h4::before,
    button:focus h4::before {
        border-color: $light-blue;
        color: $light-blue;
    }

    &.expanded {
        h4::before {
            background-color: $light-blue;
            transform: rotate(45deg);
        }

        h4::before,
        button:hover h4::before,
        button:focus h4::before {
            color: $white;
        }

        .section-content {
            max-height: 1750px;
            transition: all 1s ease-out;
        }
    }

    button {
        background-color: $white;
        border: 0;
        margin-bottom: 3px;
        padding-bottom: 0;
        text-align: left;
        width: 100%;
    }
}

.section-content {
    display: block;
    margin-left: 42px;
    max-height: 0;
    overflow: hidden;
    transition: all .5s ease-out;
}

aside {
    border-left: 1px solid $light-blue;
    float: right;
    height: 100%;
    width: 33%;

    h5 {
        border-bottom: 1px solid $grey;
        display: block;
        margin-left: 20px;
        padding-bottom: 14px;
    }

    i {
        float: right;
    }

    .fa {
        font-size: 12px;
        line-height: 16px;
    }

    ul {
        color: $dark-blue;
        padding-top: 20px;
        position: relative;
    }

    li {
        margin-bottom: 16px;
        padding-left: 20px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .active {
        font-weight: bold;

        &::before,
        &::after {
            border: solid transparent;
            border-color: transparent;
            content: ' ';
            height: 0;
            left: 0%;
            // pointer-events: none;
            position: absolute;
            top: 50%;
            width: 0;
        }

        &::after {
            border-left-color: $white;
            border-width: 5px;
            left: -2px;
            margin-top: -5px;
        }

        &::before {
            border-left-color: $light-blue;
            border-width: 6px;
            left: -1px;
            margin-top: -6px;
        }

        a {
            color: $light-blue;
        }
    }

    &.info-box {
        border-left: 0;
        font-size: 14px;
        margin-bottom: 30px;
        position: relative;

        a {
          display: block;
          text-decoration: none;
        }

        a:hover .icon-chevron {
            left: 36px;
        }

        img {
            background-color: $light-blue;
            vertical-align: bottom;
            width: 100%;
        }

        div {
            background-color: $dark-blue;
        }

        h3 {
            font-size: 19px;
        }

        p {
            font-size: 15px;
        }

        h3,
        p {
            color: $white;
        }

        .grid-info {
            padding-top: 20px;
            position: relative;

            div {
                transform: translateY(0);
            }
        }
    }

    &.search-box {
        border: 0;
        margin-top: 35px;
        padding: 19px 13px 18px 5px;

        .quick-search {
            margin: 0;
            padding: 19px 0;

            input {
                width: 100%;
            }
        }
    }
}

table {
    font-size: 14px;
    line-height: 19px;
    margin: 27px 0 40px;
    table-layout: auto;
    width: 100%;

    thead {
        background-color: $dark-blue;
        height: 50px;
    }

    th,
    td {
        font-weight: normal;
        padding: 14px 19px 17px;
        text-align: left;
    }

    th {
        border-right: 1px solid $table-head-border-color;
        color: $white;
    }

    tbody {
        tr:nth-child(2n) {
            background-color: $grey;
        }

        tr:nth-child(2n+1) {
            background-color: $white;
        }
    }
}

// Calculator styles
.calculator {
    background-color: $grey;
    display: inline-block;
    // margin-top: 15px;
    padding: 29px 19px 33px 21px;

    h3 {
        padding-bottom: 12px;
    }

    p {
        margin-bottom: 26px;
    }

    .calc-fields {
        border-bottom: 1px solid $translucent-border;
        border-top: 1px solid $translucent-border;
        display: table;
        padding: 8px 0 20px;

        label {
            color: $dark-blue;
            display: inline-block;
            font-size: 17px;
            line-height: 21px;
            width: calc(100% - 30px);
            height: 52px;
            vertical-align: top;
        }

        div {
            display: table-cell;
            vertical-align: bottom;
            width: 33%;
        }

        input,
        select {
            border: 1px solid $translucent-border;
            height: 30px;
            margin-top: 15px;
            width: 167px;

            &.percentage {
                padding-left: 13px;
            }
        }

        select {
            -webkit-appearance: none;
            padding-left: 9px;
            border-radius: 0;
            cursor: pointer;
        }
    }

    .amount,
    .frequency {
        background-position: 50% -1px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .amount {
        background-image: url('../img/currency.png');
        padding-left: 26px;
    }

    .frequency {
        background-color: $white;
        background-image: url('../img/dropdown.png');
    }

    .years {
        padding-left: 13px;
    }
}


.submit-results {
    height: 110px;
    position: relative;

    [type="button"] {
        background-color: $dark-blue;
        color: $white;
        display: block;
        font-size: 20px;
        height: auto;
        left: 0;
        // margin-top: 26px;
        margin: 26px auto 0;
        padding: 10px;
        top: 0;
        width: 227px;
    }

    span {
        color: $light-blue;
        display: inline-block;
        font-size: 52px;
        margin-top: 10px;
        opacity: 1;
        text-align: center;
        width: 100%;
    }
}

// Search styles
.searchResultContainer {

    li {
        display: inline-block;
        list-style: none;
        padding: initial;


        &:before {
            content: none;
        }
    }

    h2 {
        background-color: initial;
        color: $dark-blue;
        padding: 20px 0;
    }

    ol {
        list-style: none;
        margin-bottom: 30px;

        li {
            background-color: $grey;
            display: block;
            margin-bottom: 20px;
            padding: 20px 20px 8px;
        }
    }


}

.sisea-paging {
    display: block;
    margin-bottom: 31px;

    li {
        text-align: center;
        width: 18px;

        a {
            display: inline-block;
            width: 18px
        }

        &.disabled {
            background-color: $light-blue;
            color: $white;
        }
    }

}

.searchResults{

    .highlight {
        background-color: $highlight;
    }
}




//----------------------------------------------------
//      Tablet styles
//----------------------------------------------------
@media screen and (min-width:$wrapper-width-mobile) and (max-width:$wrapper-width-desktop - 1) {
    .hidden-tablet {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .hidden-desktop,
    .hidden-mobile {
        border: 0;
        clip: auto;
        height: auto;
        margin: auto;
        overflow: auto;
        padding: 0;
        position: initial;
        width: auto;
    }

    .feature.feature-top h2 {
        @include responsive-text(13,18,700,1024);
    }

    aside {

        h5 {
            padding: 0;
        }

        ul {
            padding-top: 5px;
        }

        li {
            margin-bottom: 17px;
        }
    }

    .page-header {
        padding: 0;
    }

    .content-wrapper {
        padding: 44px 32px;
    }

    .title-wrapper {
        padding-left: 0;
        width: 100%;

        h2 {
          padding: 20px 32px;
        }
    }

    .grid-info {
        padding: 15px 11px;
    }

    // Calculator styles
    .calculator {
        padding: 27px 25px 36px;

        p {
            margin-bottom: 6px;
        }

        .calc-fields {
            display: block;
            padding: 0;

            label {
                display: inline-block;
                font-size: 16px;
                height: 32px;
                padding-right: 20px;
                width: 50%;
                height: 22px;
            }

            div {
                border-bottom: 1px solid $translucent-border;
                display: block;
                padding: 20px 0 26px;
                width: 100%;

                &:last-child {
                    border: 0;
                }
            }

            input,
            select {
                display: inline;
                float: right;
                margin: 0;
                max-width: 167px;
                width: 50%;
            }
        }

        .amount {
            background-position: initial;
        }
    }

    .submit-results {
        height: auto;

        [type="button"] {
            margin: 26px auto 0;
            max-width: 227px;
            position: initial;
            width: 100%;
        }

        span {
            display: block;
            float: none;
            font-size: 40px;
            margin: 20px auto 0;
        }
    }

    aside.search-box .quick-search {
        position: relative;
    }
}

//----------------------------------------------------
//      Mobile styles
//----------------------------------------------------
@media screen and (max-width:$wrapper-width-mobile - 1) {
    article,
    aside,
    [class*='col-'],
    .title-wrapper,
    .calculator {
        width: 100%;
    }

    .hidden-desktop,
    .hidden-mobile {
        border: 0;
        clip: inherit;
        height: inherit;
        margin: inherit;
        overflow: inherit;
        padding: initial;
        position: static;
        width: auto;
    }

    .feature.feature-top h2 {
        @include responsive-text(12, 18, 320, 700);
    }

    .expanded {
        max-height: 1500px;
        transition: all 1s ease-out;
    }

    .content-wrapper {
        padding: 0;
    }

    .row.row-double-height [class*='col-'] {
        padding-top: 100%;
    }

    .row.mobile-square [class*='col-'] {
        padding-top: 100%;

        .grid-info,
        .grid-img {
            width: 100%;
        }

        .grid-info {
            bottom: 0;
            height: 40%;
            top: auto;
        }

        .grid-img {
            height: 60%;
            top: 0;
        }
    }

    .grid-info {
        padding: 33px 12px 22px;

        &.mobile-arrow-up::after {
          left: 50%;
          top: -20px;
          transform: translate(-50%, 0);
          @include arrow($width: 20px, $height: 10px, $color: $white, $direction: up);
        }
    }

    .info-icon {
        border-width: 1px;
        margin-top: 0;
    }

    [class*='col-'] {
        padding-top: 50%;
        position: relative;
    }

    .row.alt .col-2 {
        float: none;

        &:first-child {
            position: relative;
            right: auto;
        }
    }

    .page-header {
        padding: 0;
    }

    .title-wrapper {
        padding-left: 0;

        h1 {
            max-width: 90%;
        }

        h2 {
            max-width: 100%;
            width: 100%;
        }
    }

    article {
        margin-top: 0;
        padding: 20px;
    }

    aside {
        border-left: 0;

        a {
            display: block;
        }

        h5 {
            cursor: pointer;
            display: block;
            margin: 0;
            padding: 20px;

            .nav-open,
            &.sidebar-open .nav-closed {
                opacity: 0;
            }

            .nav-closed,
            &.sidebar-open .nav-open {
                opacity: 1;
            }

            i {
                color: $light-blue;
                float: right;
                font-size: 15px;
                right: 0;
                transition: all .5s linear;
            }

            &:hover {
                color: $dark-blue;
            }

            .fa {
                position: absolute;
                right: 20px;
            }
        }

        ul {
            display: block;
            height: 100%;
            padding-top: 0;

            &.expanded {
                padding-top: 20px;
            }
        }

        li {
            border-bottom: 1px solid $grey;
            padding: 0 20px 13px;

            &.active::before,
            &.active::after {
                border: 0;
            }

            &:hover a {
                color: $light-blue;
            }
        }

        &.info-box {
          height: auto;
          margin-top: 0;
          padding: 0 20px;
          top: 0;
          width: 100%;

            img {
                height: 60%;
            }

            > div {
                height: 40%;
            }
        }

        &.search-box {
            display: none;

        }
    }

nav ul,
aside ul {
    max-height: 0;
    overflow-y: hidden;
    transition: all .5s ease-out;
}

// Calculator styles
.calculator {
    margin: 15px 0 58px;
    padding: 29px 19px 33px 21px;

    p {
        margin-bottom: 21px;
    }

    .calc-fields {
        display: block;

        label {
            font-size: 16px;
            height: 21px;
            line-height: 18px;
            width: 100%;
        }

        div {
            border-bottom: 1px solid $translucent-border;
            display: block;
            padding: 8px 0 15px;
            width: 100%;

            &:last-child {
                border: 0;
            }
        }

        input,
        select {
            width: 100%;
        }
    }

    .amount,
    .frequency {
        background-position: 100% -1px;
        background-size: contain;
    }

    .amount {
        background-position: 0% -1px;
    }
}

    .submit-results {
        height: auto;

        [type="button"] {
            height: 39px;
            margin: 26px auto;
            max-width: 239px;
            padding: 5px;
            position: initial;
            width: 100%;
        }

        span {
            display: block;
            float: none;
            font-size: 30px;
            margin: 20px auto 3px;
        }
    }
}

@media screen and (min-width: 1400px) {

    .row a:hover .icon-chevron,
    .row a:focus .icon-chevron {
        left: 39px;
    }

    .grid-info {
        .row & {
            padding: 70px;

            .info-icon {
                height: 32px;
                width: 32px;
            }
        }

        &.arrow-left::after,
        &.arrow-right::after,
        &.arrow-up::after {
            border-width: 20px;
        }

        &.arrow-left::after {
            left: -20px;
        }

        &.arrow-right::after {
            right: -20px;
        }

        &.arrow-up::after {
            top: -20px;
        }
    }
}


//----------------------------------------------------
//      accessibliity thing
//----------------------------------------------------

a,
button,
input,
select,
.button,
textarea,
input[type=checkbox] + label,
input[type=radio] + label,
label {
    &:focus {
        outline: 3px inset $accessibility;
    }

    &.no-focus {
        outline: 0;
    }
}

a:focus {
    .rectangle & .grid-info {
        background-color: $accessibility-background;
    }

    .feature-text {
        h3,
        p {
            color: $light-blue;
        }
    }

    .row & {
        outline: 0;

        .icon-chevron {
            left: 36px;
        }
    }

    .arrow-left::after {
        border-right-color: $accessibility-background;
    }

    .arrow-right::after {
        border-left-color: $accessibility-background;
    }

    .arrow-up::after,
    .mobile-arrow-up::after {
        border-bottom-color: $accessibility-background;
        border-left-color: transparent;
    }
}
