//----------------------------------------------------
//      header
//----------------------------------------------------

header {
    background-color: $white;
    display: flex;
    height: 90px;
    position: relative;

    [type=button] {
        background-color: $white;
        border: 0;
        display: none;
        height: 16px;
        position: absolute;
        right: 20px;
        top: 38px;
        width: 59px;
    }
}

[class*='header-'] {
    height: 100%;
}

[class*='header-logo-'] {
    display: inline;
}

.header-logo-left {
    float: left;
    width: 116px;

    a {
        display: inline-block;
    }

    img {
        height: 100%;
        padding: 15px 35px 14px 20px;
        width: 100%;
    }
}

nav {
    background-color: $white;
    height: 100%;
    margin: 0 auto;
    width: 800px;

    ul {
        background-color: $white;
        text-align: center;
    }

    // Dropdown menus
    > ul {
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding-top: 37px;

        > li {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            > a {
                height: 100%;
                display: block;
            }

            &.active > a:after,
            > a:hover:after {
                opacity: 1;
                width: 100%;
            }

            ul {
                font-size: 14px;
                opacity: 0;
                padding: 20px;
                position: absolute;
                transition: .5s opacity linear;
                width: 210px;
                z-index: -1;

                li {
                    padding: 10px 0;
                    text-align: left;
                }

                &.js-access {
                    opacity: 1;
                    z-index: 9999;
                }
            }

            &:hover ul {
                opacity: 1;
                z-index: 9999;
            }

            &:hover > a,
            &.active > a {
                color: $light-blue;

            }

            &.active > a {
                font-weight: bold;
            }
        }
    }

    a:focus + ul {
        opacity: 1;
        z-index: 9999;
    }

    li {
        position: relative;

        a:hover,
        &.active a {
            text-decoration: none;
        }
    }

    a::after {
        background: $light-blue;
        border-radius: 3px;
        bottom: 0;
        content: '';
        height: 3px;
        left: 0;
        opacity: .5;
        position: absolute;
        transition: .3s ease;
        width: 0;
    }
}

.quick-search {
    margin-left: 30px;
    padding-right: 20px;
    padding-top: 25px;

    fieldset {
        border: 0;
        padding: 0;
        position: relative;
    }

    input {
        border: 1px solid $grey;
        color: $dark-blue;
        font-size: 14px;
        height: 38px;
        padding-left: 5px;
        width: 173px;
    }

    button {
        border: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include imgreplace(38px, 100%, '../img/search-icon.png', 50%, block, center);
    }
}

@media screen and (min-width:$wrapper-width-mobile) and (max-width:$wrapper-width-desktop - 1) {
    header {
        display: block;
        height: 149px;
    }

    [class*='header-logo-'] {
        height: 90px;
    }

    nav {
        clear: both;
        height: 59px;
        position: relative;
        width: 100%;

        > ul {
            height: 59px;
            margin: 0 20px;
            padding-top: 23px;

            //  Dropdowns
            > li:hover ul {
                top: 35px;
            }

            > li:nth-child(n+6) ul {
                right: 50%;
                transform: translate(20%,0);
            }
        }

        ul {
            border-top: 1px solid $translucent-border;
            clear: both;
        }
    }

    .quick-search {
        position: absolute;
        right: 0;
        top: 0;
    }

}

@media screen and (max-width:$wrapper-width-mobile - 1) {
    header {
        display: block;
        height: 135px;
        position: relative;
    }

    header [type=button] {
        display: block;
        width: 80px;

        i,
        &:hover {
            color: $light-blue;
        }

        i {
            float: right;
            font-size: 12px;
            line-height: 18px;
            position: absolute;
            right: 0;
            transition: all .25s linear;

            &.nav-mobile-open {
                opacity: 0;
            }

        }

        span.nav-mobile-open {
            display: none;
            visibility: hidden;
        }

        &.nav-mobile-open {
            i {
                opacity: 0;

                &.nav-mobile-open {
                    opacity: 1;
                }
            }

            span {
                display: inline;
                visibility: visible;

                &.nav-mobile-closed {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }

    nav {
        height: auto;
        left: 0;
        position: absolute;
        top: 90px;
        width: 100%;
        z-index: 10;

        > ul {
            border-top: 1px solid $grey;
            display: block;
            height: 100%;
            padding: 0;

            &.nav-active {
                display: block;
                position: relative;
            }

            &.navbar-open {
                max-height: 350px;
            }

            li {
                border-bottom: 1px solid $grey;
                height: 48px;
                margin: 0 auto;
                padding: 16px 0;
                width: 87.5%;

                &:last-child {
                    margin: 0 auto 30px;
                }

                a:hover::after,
                &.active a::after {
                    opacity: 0;
                    width: 0%;
                }
            }

            // Suppress dropdowns
            li:hover ul,
            a:focus + ul {
                display: none;
            }
        }
    }

    .quick-search {
        bottom: 0;
        display: block;
        height: 44px;
        margin-left: 0;
        padding: 0;
        position: absolute;
        width: 100%;

        fieldset,
        input {
            background-color: $grey;
            border: 0;
            height: 100%;
            width: 100%;
        }

        input {
            font-size: 15px;
            padding-left: 15px;
        }

        button {
            background-position: center;
            background-size: 50%;
            height: 44px;
            right: 0;
            top: 0;
            width: 44px;
        }

    }
}
