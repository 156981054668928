.hidden {
  display: none;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
hr,
main {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

blockquote,
q {
  quotes: none; }

q {
  quotes: '\201C' '\201D' '\2018' '\2019'; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ''; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

dfn {
  font-style: italic; }

hr {
  display: block;
  box-sizing: content-box;
  height: 0;
  border-top: 1px solid #ccc; }

pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

small {
  font-size: 80%; }

b,
strong,
th {
  font-weight: bold; }

sup,
sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0;
  padding: 10px; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

img {
  -ms-interpolation-mode: bicubic;
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

nav,
.nav-mobile,
footer {
  color: #005283;
  font-size: 15px;
  line-height: 15px; }

.grid-info h3 {
  font-size: 19px;
  line-height: 19px; }

.copyright {
  color: #222; }

@media screen and (min-width: 1400px) {
  .grid-info h3 {
    font-size: 25px;
    line-height: 27px; }
  .grid-info p {
    font-size: 20px;
    line-height: 22px; } }

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .grid-info h3 {
    font-size: 15px;
    line-height: 17px; }
  .grid-info p {
    font-size: 13px;
    line-height: 14px; } }

@media screen and (max-width: 699px) {
  footer {
    font-size: 14px; }
  h4 {
    font-size: 15px; }
  p {
    font-size: 15px;
    line-height: 20px; }
  .title-wrapper h1 {
    font-size: 26px;
    line-height: 28px; }
  .title-wrapper h2 {
    font-size: 16px; }
  .grid-info h3 {
    font-size: 15px;
    line-height: 17px; }
  .grid-info p {
    font-size: 12px;
    line-height: 14px; }
  .copyright {
    font-size: 13px; } }

body {
  background-color: #eee;
  color: #222;
  font-family: "proxima-nova-soft", "Proxima Nova Soft", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #005283;
  font-family: "proxima-nova-soft", "Proxima Nova Soft", "Helvetica Neue", helvetica, arial, verdana, sans-serif; }

h1 {
  display: inline-block;
  font-size: 40px;
  line-height: 30px; }

h2 {
  display: inline-block;
  font-size: 22px;
  line-height: 26px; }

h3 {
  display: inline-block;
  font-size: 25px;
  line-height: 21px; }

h4 {
  display: inline-block;
  font-size: 19px;
  line-height: 18px; }

h5 {
  display: inline-block;
  font-size: 19px;
  line-height: 17px; }

h6 {
  display: inline-block;
  font-size: 14px;
  line-height: 14px; }

h1 {
  background-color: #005283;
  color: #fff;
  padding: 15px 17px 11px; }

h2 {
  background-color: rgba(0, 156, 223, 0.8);
  color: #fff;
  padding: 16px 20px 20px; }

.hidden-desktop {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

a {
  color: #005283;
  text-decoration: none; }
  a:hover {
    color: #009cdf;
    text-decoration: underline;
    text-decoration-color: #009cdf; }

ul {
  list-style: none; }

header,
footer,
article,
main,
.content-wrapper {
  margin: 0 auto;
  width: 100%; }

main,
article,
.content-wrapper {
  background-color: #fff; }

.videoWrapper {
  position: relative;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0; }

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.row {
  position: relative;
  width: 100%; }
  .row:before, .row:after {
    content: ' ';
    display: table; }
  .row:after {
    clear: both; }
  .row.row-double-height [class*='col-'] {
    padding-top: 50%; }
  .row.alt {
    position: relative; }
    .row.alt .col-2:first-child {
      position: absolute;
      right: 0; }
  .row.alt:first-child {
    float: right; }
  .row p {
    color: #222; }
  .row a:hover .grid-info:not(.feature-text) h3 {
    color: #009cdf; }
  .row a:hover .icon-chevron {
    left: 36px; }

[class*='col-'] {
  float: left;
  height: 0;
  padding-top: 25%;
  position: relative;
  width: 25%; }

.col-2 {
  width: 50%; }

.col-3 {
  width: 75%; }

.col-4 {
  width: 100%; }

.grid-img {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover; }

.feature {
  background-color: #005283;
  height: 100%;
  position: absolute;
  width: 100%; }
  .feature.feature-top {
    background-image: url("../img/money_matters_large.png");
    top: 0; }
    .feature.feature-top h2 {
      background-color: transparent;
      font-size: 1.5vw;
      line-height: 1.08;
      padding: 70.93% 13.281% 0;
      text-align: center; }
  .feature.feature-bottom {
    bottom: 0;
    top: auto; }
  .feature a {
    display: block;
    height: 100%; }

.grid-info {
  background-color: #fff;
  padding: 15px;
  z-index: 1; }
  .grid-info > div {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%); }
  .grid-info h3,
  .grid-info p {
    width: 100%; }
  .grid-info p {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
    padding-top: 8px; }
  .grid-info a {
    display: inline-block; }
  .grid-info.feature-text {
    background: linear-gradient(to bottom, transparent, black);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 52%;
    justify-content: flex-end;
    position: absolute;
    text-align: center;
    width: 100%; }
    .grid-info.feature-text h3 {
      color: #fff; }
    .grid-info.feature-text p {
      color: #fff;
      margin: 0 auto 18px;
      max-width: 400px;
      position: relative; }
    .grid-info.feature-text .info-icon {
      top: 0; }
  .grid-info.arrow-left::after, .grid-info.arrow-right::after, .grid-info.arrow-up::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%); }
  .grid-info.arrow-left::after {
    left: -10px;
    width: 0;
    height: 0;
    content: "";
    display: block;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff; }
  .grid-info.arrow-right::after {
    right: -10px;
    width: 0;
    height: 0;
    content: "";
    display: block;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff; }
  .grid-info.arrow-up::after {
    left: 50%;
    top: -10px;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    content: "";
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #005283; }

.rectangle {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .rectangle.half-height {
    height: 50%; }
  .rectangle:last-child {
    bottom: 0;
    top: auto; }
  .rectangle > a {
    display: block;
    height: 100%; }
    .rectangle > a > div {
      height: 100%;
      position: absolute;
      width: 50%; }
    .rectangle > a .quarter-width {
      width: 25%; }
    .rectangle > a .three-quarter-width {
      width: 75%; }
  .rectangle .grid-img,
  .rectangle.alt .grid-info {
    left: 0; }
  .rectangle .grid-info,
  .rectangle.alt .grid-img {
    right: 0; }
  .rectangle.alt .grid-img {
    left: auto; }
  .rectangle.alt .grid-info {
    right: auto; }

.info-icon {
  background-color: transparent;
  border: 2px solid #eee;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  margin: 15px auto 0;
  overflow: hidden;
  position: relative;
  transition: all .5s linear;
  width: 24px; }
  .info-icon i {
    color: #009cdf;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: all .25s linear; }
    .info-icon i.icon-chevron {
      font-size: 11px;
      left: 56%;
      transition: left 750ms; }
    .info-icon i.icon-chevron::after {
      content: '\f054';
      left: -24px;
      position: absolute; }
  .info-box .info-icon {
    border-color: #fff; }
  .info-icon:active, .info-icon.is-active {
    background-color: #009cdf; }
    .info-icon:active i, .info-icon.is-active i {
      color: #fff; }

.page-header {
  background-position: center;
  background-size: cover;
  height: 338px;
  position: relative; }

.title-wrapper {
  bottom: 0;
  left: 50%;
  padding-left: 42px;
  position: absolute;
  transform: translate(-50%, 0);
  width: 1024px; }
  .title-wrapper h1,
  .title-wrapper h2 {
    clear: left;
    float: left;
    line-height: 1;
    padding: 20px;
    position: relative; }
  .title-wrapper h1 {
    max-width: 80%; }
  .title-wrapper h2 {
    line-height: 1.1;
    max-width: 90%; }

.content-wrapper {
  background-color: #fff;
  max-width: 1024px;
  padding: 44px 42px 44px 62px;
  width: 100%; }
  .content-wrapper:before, .content-wrapper:after {
    content: ' ';
    display: table; }
  .content-wrapper:after {
    clear: both; }

article {
  float: left;
  margin-top: 40px;
  padding-right: 48px;
  width: 66%; }
  article h3 {
    padding-bottom: 18px; }
  article iframe {
    width: 100% !important; }
  article img {
    margin: 1em 0;
    max-width: 100%; }
  article:first-of-type {
    margin-top: 0; }
  article > div {
    width: 100%; }
    article > div table:first-child {
      margin-top: 0; }
    article > div > p:last-child,
    article > div table:last-child {
      margin-bottom: 0; }
  article p {
    margin-bottom: 1em; }
    article p a {
      text-decoration: underline; }
      article p a:hover {
        color: #009cdf;
        text-decoration-color: #009cdf; }
  article > p:last-child {
    margin-bottom: 0; }
  article ul {
    list-style: none; }
    article ul + p {
      margin-top: 2em; }
    article ul li:last-child {
      margin-bottom: 0; }
    article ul ul {
      margin: 20px 0; }
      article ul ul li::before {
        background-color: transparent;
        border-radius: 0;
        color: #009cdf;
        content: '\f054';
        font-family: 'FontAwesome';
        font-size: 10px;
        top: 3px; }
  article li {
    margin-bottom: .5em;
    padding-left: 20px;
    position: relative; }
    article li::before {
      background-color: #005283;
      border-radius: 50%;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      top: 6px;
      width: 8px; }

.expandable-section h4 {
  border-top: 1px solid #eee;
  cursor: pointer;
  line-height: 24px;
  padding: 15px 0 15px 42px;
  position: relative;
  width: 100%; }
  .expandable-section h4::before {
    background-color: transparent;
    border: 1px solid #eee;
    border-radius: 50%;
    content: '+';
    display: block;
    height: 24px;
    left: 0;
    line-height: 23px;
    margin-top: -12px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 50%;
    transform-origin: 50% 50%;
    transition: all .25s linear;
    width: 24px; }

.expandable-section button:hover h4::before,
.expandable-section button:focus h4::before {
  border-color: #009cdf;
  color: #009cdf; }

.expandable-section.expanded h4::before {
  background-color: #009cdf;
  transform: rotate(45deg); }

.expandable-section.expanded h4::before,
.expandable-section.expanded button:hover h4::before,
.expandable-section.expanded button:focus h4::before {
  color: #fff; }

.expandable-section.expanded .section-content {
  max-height: 1750px;
  transition: all 1s ease-out; }

.expandable-section button {
  background-color: #fff;
  border: 0;
  margin-bottom: 3px;
  padding-bottom: 0;
  text-align: left;
  width: 100%; }

.section-content {
  display: block;
  margin-left: 42px;
  max-height: 0;
  overflow: hidden;
  transition: all .5s ease-out; }

aside {
  border-left: 1px solid #009cdf;
  float: right;
  height: 100%;
  width: 33%; }
  aside h5 {
    border-bottom: 1px solid #eee;
    display: block;
    margin-left: 20px;
    padding-bottom: 14px; }
  aside i {
    float: right; }
  aside .fa {
    font-size: 12px;
    line-height: 16px; }
  aside ul {
    color: #005283;
    padding-top: 20px;
    position: relative; }
  aside li {
    margin-bottom: 16px;
    padding-left: 20px;
    position: relative; }
    aside li:last-child {
      margin-bottom: 0; }
  aside .active {
    font-weight: bold; }
    aside .active::before, aside .active::after {
      border: solid transparent;
      border-color: transparent;
      content: ' ';
      height: 0;
      left: 0%;
      position: absolute;
      top: 50%;
      width: 0; }
    aside .active::after {
      border-left-color: #fff;
      border-width: 5px;
      left: -2px;
      margin-top: -5px; }
    aside .active::before {
      border-left-color: #009cdf;
      border-width: 6px;
      left: -1px;
      margin-top: -6px; }
    aside .active a {
      color: #009cdf; }
  aside.info-box {
    border-left: 0;
    font-size: 14px;
    margin-bottom: 30px;
    position: relative; }
    aside.info-box a {
      display: block;
      text-decoration: none; }
    aside.info-box a:hover .icon-chevron {
      left: 36px; }
    aside.info-box img {
      background-color: #009cdf;
      vertical-align: bottom;
      width: 100%; }
    aside.info-box div {
      background-color: #005283; }
    aside.info-box h3 {
      font-size: 19px; }
    aside.info-box p {
      font-size: 15px; }
    aside.info-box h3,
    aside.info-box p {
      color: #fff; }
    aside.info-box .grid-info {
      padding-top: 20px;
      position: relative; }
      aside.info-box .grid-info div {
        transform: translateY(0); }
  aside.search-box {
    border: 0;
    margin-top: 35px;
    padding: 19px 13px 18px 5px; }
    aside.search-box .quick-search {
      margin: 0;
      padding: 19px 0; }
      aside.search-box .quick-search input {
        width: 100%; }

table {
  font-size: 14px;
  line-height: 19px;
  margin: 27px 0 40px;
  table-layout: auto;
  width: 100%; }
  table thead {
    background-color: #005283;
    height: 50px; }
  table th,
  table td {
    font-weight: normal;
    padding: 14px 19px 17px;
    text-align: left; }
  table th {
    border-right: 1px solid rgba(0, 156, 223, 0.2);
    color: #fff; }
  table tbody tr:nth-child(2n) {
    background-color: #eee; }
  table tbody tr:nth-child(2n+1) {
    background-color: #fff; }

.calculator {
  background-color: #eee;
  display: inline-block;
  padding: 29px 19px 33px 21px; }
  .calculator h3 {
    padding-bottom: 12px; }
  .calculator p {
    margin-bottom: 26px; }
  .calculator .calc-fields {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: table;
    padding: 8px 0 20px; }
    .calculator .calc-fields label {
      color: #005283;
      display: inline-block;
      font-size: 17px;
      line-height: 21px;
      width: calc(100% - 30px);
      height: 52px;
      vertical-align: top; }
    .calculator .calc-fields div {
      display: table-cell;
      vertical-align: bottom;
      width: 33%; }
    .calculator .calc-fields input,
    .calculator .calc-fields select {
      border: 1px solid rgba(0, 0, 0, 0.1);
      height: 30px;
      margin-top: 15px;
      width: 167px; }
      .calculator .calc-fields input.percentage,
      .calculator .calc-fields select.percentage {
        padding-left: 13px; }
    .calculator .calc-fields select {
      -webkit-appearance: none;
      padding-left: 9px;
      border-radius: 0;
      cursor: pointer; }
  .calculator .amount,
  .calculator .frequency {
    background-position: 50% -1px;
    background-repeat: no-repeat;
    background-size: cover; }
  .calculator .amount {
    background-image: url("../img/currency.png");
    padding-left: 26px; }
  .calculator .frequency {
    background-color: #fff;
    background-image: url("../img/dropdown.png"); }
  .calculator .years {
    padding-left: 13px; }

.submit-results {
  height: 110px;
  position: relative; }
  .submit-results [type="button"] {
    background-color: #005283;
    color: #fff;
    display: block;
    font-size: 20px;
    height: auto;
    left: 0;
    margin: 26px auto 0;
    padding: 10px;
    top: 0;
    width: 227px; }
  .submit-results span {
    color: #009cdf;
    display: inline-block;
    font-size: 52px;
    margin-top: 10px;
    opacity: 1;
    text-align: center;
    width: 100%; }

.searchResultContainer li {
  display: inline-block;
  list-style: none;
  padding: initial; }
  .searchResultContainer li:before {
    content: none; }

.searchResultContainer h2 {
  background-color: initial;
  color: #005283;
  padding: 20px 0; }

.searchResultContainer ol {
  list-style: none;
  margin-bottom: 30px; }
  .searchResultContainer ol li {
    background-color: #eee;
    display: block;
    margin-bottom: 20px;
    padding: 20px 20px 8px; }

.sisea-paging {
  display: block;
  margin-bottom: 31px; }
  .sisea-paging li {
    text-align: center;
    width: 18px; }
    .sisea-paging li a {
      display: inline-block;
      width: 18px; }
    .sisea-paging li.disabled {
      background-color: #009cdf;
      color: #fff; }

.searchResults .highlight {
  background-color: rgba(0, 156, 223, 0.3); }

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .hidden-tablet {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .hidden-desktop,
  .hidden-mobile {
    border: 0;
    clip: auto;
    height: auto;
    margin: auto;
    overflow: auto;
    padding: 0;
    position: initial;
    width: auto; }
  .feature.feature-top h2 {
    font-size: calc(13px + (18 - 13) * ((100vw - 700px) / (1024 - 700))); }
  aside h5 {
    padding: 0; }
  aside ul {
    padding-top: 5px; }
  aside li {
    margin-bottom: 17px; }
  .page-header {
    padding: 0; }
  .content-wrapper {
    padding: 44px 32px; }
  .title-wrapper {
    padding-left: 0;
    width: 100%; }
    .title-wrapper h2 {
      padding: 20px 32px; }
  .grid-info {
    padding: 15px 11px; }
  .calculator {
    padding: 27px 25px 36px; }
    .calculator p {
      margin-bottom: 6px; }
    .calculator .calc-fields {
      display: block;
      padding: 0; }
      .calculator .calc-fields label {
        display: inline-block;
        font-size: 16px;
        height: 32px;
        padding-right: 20px;
        width: 50%;
        height: 22px; }
      .calculator .calc-fields div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: block;
        padding: 20px 0 26px;
        width: 100%; }
        .calculator .calc-fields div:last-child {
          border: 0; }
      .calculator .calc-fields input,
      .calculator .calc-fields select {
        display: inline;
        float: right;
        margin: 0;
        max-width: 167px;
        width: 50%; }
    .calculator .amount {
      background-position: initial; }
  .submit-results {
    height: auto; }
    .submit-results [type="button"] {
      margin: 26px auto 0;
      max-width: 227px;
      position: initial;
      width: 100%; }
    .submit-results span {
      display: block;
      float: none;
      font-size: 40px;
      margin: 20px auto 0; }
  aside.search-box .quick-search {
    position: relative; } }

@media screen and (max-width: 699px) {
  article,
  aside,
  [class*='col-'],
  .title-wrapper,
  .calculator {
    width: 100%; }
  .hidden-desktop,
  .hidden-mobile {
    border: 0;
    clip: inherit;
    height: inherit;
    margin: inherit;
    overflow: inherit;
    padding: initial;
    position: static;
    width: auto; }
  .feature.feature-top h2 {
    font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (700 - 320))); }
  .expanded {
    max-height: 1500px;
    transition: all 1s ease-out; }
  .content-wrapper {
    padding: 0; }
  .row.row-double-height [class*='col-'] {
    padding-top: 100%; }
  .row.mobile-square [class*='col-'] {
    padding-top: 100%; }
    .row.mobile-square [class*='col-'] .grid-info,
    .row.mobile-square [class*='col-'] .grid-img {
      width: 100%; }
    .row.mobile-square [class*='col-'] .grid-info {
      bottom: 0;
      height: 40%;
      top: auto; }
    .row.mobile-square [class*='col-'] .grid-img {
      height: 60%;
      top: 0; }
  .grid-info {
    padding: 33px 12px 22px; }
    .grid-info.mobile-arrow-up::after {
      left: 50%;
      top: -20px;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      content: "";
      display: block;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff; }
  .info-icon {
    border-width: 1px;
    margin-top: 0; }
  [class*='col-'] {
    padding-top: 50%;
    position: relative; }
  .row.alt .col-2 {
    float: none; }
    .row.alt .col-2:first-child {
      position: relative;
      right: auto; }
  .page-header {
    padding: 0; }
  .title-wrapper {
    padding-left: 0; }
    .title-wrapper h1 {
      max-width: 90%; }
    .title-wrapper h2 {
      max-width: 100%;
      width: 100%; }
  article {
    margin-top: 0;
    padding: 20px; }
  aside {
    border-left: 0; }
    aside a {
      display: block; }
    aside h5 {
      cursor: pointer;
      display: block;
      margin: 0;
      padding: 20px; }
      aside h5 .nav-open,
      aside h5.sidebar-open .nav-closed {
        opacity: 0; }
      aside h5 .nav-closed,
      aside h5.sidebar-open .nav-open {
        opacity: 1; }
      aside h5 i {
        color: #009cdf;
        float: right;
        font-size: 15px;
        right: 0;
        transition: all .5s linear; }
      aside h5:hover {
        color: #005283; }
      aside h5 .fa {
        position: absolute;
        right: 20px; }
    aside ul {
      display: block;
      height: 100%;
      padding-top: 0; }
      aside ul.expanded {
        padding-top: 20px; }
    aside li {
      border-bottom: 1px solid #eee;
      padding: 0 20px 13px; }
      aside li.active::before, aside li.active::after {
        border: 0; }
      aside li:hover a {
        color: #009cdf; }
    aside.info-box {
      height: auto;
      margin-top: 0;
      padding: 0 20px;
      top: 0;
      width: 100%; }
      aside.info-box img {
        height: 60%; }
      aside.info-box > div {
        height: 40%; }
    aside.search-box {
      display: none; }
  nav ul,
  aside ul {
    max-height: 0;
    overflow-y: hidden;
    transition: all .5s ease-out; }
  .calculator {
    margin: 15px 0 58px;
    padding: 29px 19px 33px 21px; }
    .calculator p {
      margin-bottom: 21px; }
    .calculator .calc-fields {
      display: block; }
      .calculator .calc-fields label {
        font-size: 16px;
        height: 21px;
        line-height: 18px;
        width: 100%; }
      .calculator .calc-fields div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: block;
        padding: 8px 0 15px;
        width: 100%; }
        .calculator .calc-fields div:last-child {
          border: 0; }
      .calculator .calc-fields input,
      .calculator .calc-fields select {
        width: 100%; }
    .calculator .amount,
    .calculator .frequency {
      background-position: 100% -1px;
      background-size: contain; }
    .calculator .amount {
      background-position: 0% -1px; }
  .submit-results {
    height: auto; }
    .submit-results [type="button"] {
      height: 39px;
      margin: 26px auto;
      max-width: 239px;
      padding: 5px;
      position: initial;
      width: 100%; }
    .submit-results span {
      display: block;
      float: none;
      font-size: 30px;
      margin: 20px auto 3px; } }

@media screen and (min-width: 1400px) {
  .row a:hover .icon-chevron,
  .row a:focus .icon-chevron {
    left: 39px; }
  .row .grid-info {
    padding: 70px; }
    .row .grid-info .info-icon {
      height: 32px;
      width: 32px; }
  .grid-info.arrow-left::after, .grid-info.arrow-right::after, .grid-info.arrow-up::after {
    border-width: 20px; }
  .grid-info.arrow-left::after {
    left: -20px; }
  .grid-info.arrow-right::after {
    right: -20px; }
  .grid-info.arrow-up::after {
    top: -20px; } }

a:focus,
button:focus,
input:focus,
select:focus,
.button:focus,
textarea:focus,
input[type=checkbox] + label:focus,
input[type=radio] + label:focus,
label:focus {
  outline: 3px inset #f00; }

a.no-focus,
button.no-focus,
input.no-focus,
select.no-focus,
.button.no-focus,
textarea.no-focus,
input[type=checkbox] + label.no-focus,
input[type=radio] + label.no-focus,
label.no-focus {
  outline: 0; }

.rectangle a:focus .grid-info {
  background-color: rgba(0, 156, 223, 0.3); }

a:focus .feature-text h3,
a:focus .feature-text p {
  color: #009cdf; }

.row a:focus {
  outline: 0; }
  .row a:focus .icon-chevron {
    left: 36px; }

a:focus .arrow-left::after {
  border-right-color: rgba(0, 156, 223, 0.3); }

a:focus .arrow-right::after {
  border-left-color: rgba(0, 156, 223, 0.3); }

a:focus .arrow-up::after,
a:focus .mobile-arrow-up::after {
  border-bottom-color: rgba(0, 156, 223, 0.3);
  border-left-color: transparent; }

header {
  background-color: #fff;
  display: flex;
  height: 90px;
  position: relative; }
  header [type=button] {
    background-color: #fff;
    border: 0;
    display: none;
    height: 16px;
    position: absolute;
    right: 20px;
    top: 38px;
    width: 59px; }

[class*='header-'] {
  height: 100%; }

[class*='header-logo-'] {
  display: inline; }

.header-logo-left {
  float: left;
  width: 116px; }
  .header-logo-left a {
    display: inline-block; }
  .header-logo-left img {
    height: 100%;
    padding: 15px 35px 14px 20px;
    width: 100%; }

nav {
  background-color: #fff;
  height: 100%;
  margin: 0 auto;
  width: 800px; }
  nav ul {
    background-color: #fff;
    text-align: center; }
  nav > ul {
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding-top: 37px; }
    nav > ul > li {
      margin-right: 10px; }
      nav > ul > li:last-child {
        margin-right: 0; }
      nav > ul > li > a {
        height: 100%;
        display: block; }
      nav > ul > li.active > a:after,
      nav > ul > li > a:hover:after {
        opacity: 1;
        width: 100%; }
      nav > ul > li ul {
        font-size: 14px;
        opacity: 0;
        padding: 20px;
        position: absolute;
        transition: .5s opacity linear;
        width: 210px;
        z-index: -1; }
        nav > ul > li ul li {
          padding: 10px 0;
          text-align: left; }
        nav > ul > li ul.js-access {
          opacity: 1;
          z-index: 9999; }
      nav > ul > li:hover ul {
        opacity: 1;
        z-index: 9999; }
      nav > ul > li:hover > a,
      nav > ul > li.active > a {
        color: #009cdf; }
      nav > ul > li.active > a {
        font-weight: bold; }
  nav a:focus + ul {
    opacity: 1;
    z-index: 9999; }
  nav li {
    position: relative; }
    nav li a:hover,
    nav li.active a {
      text-decoration: none; }
  nav a::after {
    background: #009cdf;
    border-radius: 3px;
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    opacity: .5;
    position: absolute;
    transition: .3s ease;
    width: 0; }

.quick-search {
  margin-left: 30px;
  padding-right: 20px;
  padding-top: 25px; }
  .quick-search fieldset {
    border: 0;
    padding: 0;
    position: relative; }
  .quick-search input {
    border: 1px solid #eee;
    color: #005283;
    font-size: 14px;
    height: 38px;
    padding-left: 5px;
    width: 173px; }
  .quick-search button {
    border: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
    width: 38px;
    height: 100%;
    background-color: transparent;
    background-image: url("../img/search-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%; }

@media screen and (min-width: 700px) and (max-width: 1023px) {
  header {
    display: block;
    height: 149px; }
  [class*='header-logo-'] {
    height: 90px; }
  nav {
    clear: both;
    height: 59px;
    position: relative;
    width: 100%; }
    nav > ul {
      height: 59px;
      margin: 0 20px;
      padding-top: 23px; }
      nav > ul > li:hover ul {
        top: 35px; }
      nav > ul > li:nth-child(n+6) ul {
        right: 50%;
        transform: translate(20%, 0); }
    nav ul {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      clear: both; }
  .quick-search {
    position: absolute;
    right: 0;
    top: 0; } }

@media screen and (max-width: 699px) {
  header {
    display: block;
    height: 135px;
    position: relative; }
  header [type=button] {
    display: block;
    width: 80px; }
    header [type=button] i, header [type=button]:hover {
      color: #009cdf; }
    header [type=button] i {
      float: right;
      font-size: 12px;
      line-height: 18px;
      position: absolute;
      right: 0;
      transition: all .25s linear; }
      header [type=button] i.nav-mobile-open {
        opacity: 0; }
    header [type=button] span.nav-mobile-open {
      display: none;
      visibility: hidden; }
    header [type=button].nav-mobile-open i {
      opacity: 0; }
      header [type=button].nav-mobile-open i.nav-mobile-open {
        opacity: 1; }
    header [type=button].nav-mobile-open span {
      display: inline;
      visibility: visible; }
      header [type=button].nav-mobile-open span.nav-mobile-closed {
        display: none;
        visibility: hidden; }
  nav {
    height: auto;
    left: 0;
    position: absolute;
    top: 90px;
    width: 100%;
    z-index: 10; }
    nav > ul {
      border-top: 1px solid #eee;
      display: block;
      height: 100%;
      padding: 0; }
      nav > ul.nav-active {
        display: block;
        position: relative; }
      nav > ul.navbar-open {
        max-height: 350px; }
      nav > ul li {
        border-bottom: 1px solid #eee;
        height: 48px;
        margin: 0 auto;
        padding: 16px 0;
        width: 87.5%; }
        nav > ul li:last-child {
          margin: 0 auto 30px; }
        nav > ul li a:hover::after,
        nav > ul li.active a::after {
          opacity: 0;
          width: 0%; }
      nav > ul li:hover ul,
      nav > ul a:focus + ul {
        display: none; }
  .quick-search {
    bottom: 0;
    display: block;
    height: 44px;
    margin-left: 0;
    padding: 0;
    position: absolute;
    width: 100%; }
    .quick-search fieldset,
    .quick-search input {
      background-color: #eee;
      border: 0;
      height: 100%;
      width: 100%; }
    .quick-search input {
      font-size: 15px;
      padding-left: 15px; }
    .quick-search button {
      background-position: center;
      background-size: 50%;
      height: 44px;
      right: 0;
      top: 0;
      width: 44px; } }

footer {
  height: 116px; }

.logos {
  display: inline-block;
  float: left;
  height: 100%; }
  .logos:before, .logos:after {
    content: ' ';
    display: table; }
  .logos:after {
    clear: both; }
  .logos a {
    display: inline-block;
    height: 100%; }
  .logos img {
    height: 100%;
    padding: 33px 37px; }

.meta {
  display: inline-block;
  float: right;
  height: 100%;
  padding: 33px 42px 40px; }
  .meta ul {
    clear: right;
    display: block;
    float: right;
    margin-bottom: 14px;
    text-align: center; }
  .meta li {
    display: inline;
    padding-left: 9px; }
    .meta li + li::before {
      color: rgba(0, 156, 223, 0.2);
      content: '\2022';
      padding-right: 9px; }
    .meta li:first-child {
      padding-left: 0; }

@media screen and (max-width: 699px) {
  footer {
    height: 181px; }
  .logos {
    display: block;
    float: none;
    height: 91px;
    position: relative;
    width: 100%; }
    .logos a {
      margin: 0 50%;
      position: absolute;
      transform: translate(-50%, 0); }
    .logos img {
      padding: 20px 0;
      position: relative; }
  .meta {
    float: none;
    height: 90px;
    padding: 0;
    width: 100%; }
    .meta ul {
      float: none;
      height: 44px;
      margin-bottom: 0;
      padding: 14px 22px 16px; }
    .meta .links {
      background-color: #fff; }
    .meta .copyright li + li::before {
      content: ''; }
    .meta .copyright li:first-child {
      float: left; }
    .meta .copyright li:last-child {
      float: right; } }
